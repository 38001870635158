import React, { useState, useEffect } from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
`
const Title = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
`

const PositionElement = styled.h4`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-weight: lighter;
`

function roundTo(n, digits) {
  var negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(2)
  if (negative) {
    n = (n * -1).toFixed(2)
  }
  return n
}

const getPosition = () =>
  new Promise(function(resolve, reject) {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })

const usePosition = () => {
  const [position, setPosition] = useState(undefined)
  useEffect(() => getPosition().then(setPosition), [])
  return position
}

const Page = () => {
  const position = usePosition()

  return (
    <Container>
      <Title>What 2 Numbers?</Title>

      {position && (
        <>
          <PositionElement>
            {position.coords.latitude.toFixed(5)}
          </PositionElement>
          <PositionElement>
            {position.coords.longitude.toFixed(5)}
          </PositionElement>
        </>
      )}
    </Container>
  )
}

export default Page
